import React from "react"
import { Link } from "gatsby"
import * as styles from "./styles.module.css"

export default () => {
  return (
    <div className={styles.container}>
      <h3>
        Portfolio Breakdown weightings examples for different sizes from $35,000
        up to $5,000,000
      </h3>

      <Link to="/signup" className="btn">
        Click here to sign up now
      </Link>
    </div>
  )
}
