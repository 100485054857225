import React, { useState, Fragment } from "react"
import { Link } from "gatsby"
//import Modal from "react-modal"
import { getValFromLS } from "../utils/helpers"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../indexPage/One"
import Two from "../indexPage/Two"
//import Pwd from "../indexPage/Pwd"
import Footer from "../components/Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

/*
  const modalStyles = {
    overlay: {
      zIndex: 100,
      background: "rgba(0, 0, 0, 0.7)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  Modal.setAppElement("#___gatsby")
*/

const IndexPage = () => {
  //const [modal, setModal] = useState(true)

  const entered = true

  /* enable password for development preview
  const [entered, setEntered] = useState(true)
  useEffect(() => {
    if (getValFromLS("etfsrPwd") || getValFromLS("etfsrPwd", true)) {
      setEntered(true)
    }
  }, [])

  const handleSubmitted = bool => {
    setValToLS("etfsrPwd", bool, true)
    setEntered(bool)
  }



  const afterOpenModal = () => {
    document.body.style.overflow = "hidden"
  }

  const afterCloseModal = () => {
    document.body.removeAttribute("style")
  }

  const closeModal = () => {
    setModal(false)
  }*/

  return (
    <Fragment>
      {/*!getValFromLS("etfsrToken", true) && (
        <Modal
          isOpen={modal}
          onAfterOpen={afterOpenModal}
          onAfterClose={afterCloseModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Welcome"
        >
          <div className="modalCloseContainer">
            <button type="button" className="naked" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
          <div className="modalContent">
            <p style={{ padding: "25px 25px 0 25px" }}>
              Your alternative to buying ETFs daily breakdown of the top stocks
              in various portfolio sizes $35K to $5 Million. Daily performance
              statistics.
            </p>
            <p style={{ padding: "25px" }}>2 Week FREE Trial</p>

            <Link to={`/signup`} className="btn green">
              Access My Portfolio Now
            </Link>
          </div>
        </Modal>
      )*/}

      <Helmet
        slug=""
        title="Stock Picks and Financial Research - Improve Portfolio Performance"
        descr="The ETF Stock Report provides stock picks expected to outperform the underlying stock index. We offer a daily performance report to help you tailor your own stock portfolio."
      />
      {entered && <Header />}
      {entered && <One />}
      {entered && <Two />}
      {entered && <Footer />}
      {/*!entered && <Pwd submitted={handleSubmitted} />*/}
    </Fragment>
  )
}

export default IndexPage
