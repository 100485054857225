import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="ETF Stock Report"></H1>

      <div className={styles.grid}>
        <div>
          <p>
            <strong>ETF Stock Report</strong> provides a daily breakdown of the
            top thirty-five stocks, in various portfolio sizes, performing
            against the major market indexes.
          </p>

          <p>
            We know investors can be overwhelmed by the sheer volume of
            financial news. Our report provides a daily performance report to
            help you tailor your own portfolio.
          </p>

          <p>
            There are thousands of publicly traded stocks, we focus on the ones
            that are driving the market.
          </p>
        </div>

        <div>
          <span>
            “With today’s headlines, it has never been more important to be more
            selective with your investments.”
          </span>
        </div>
      </div>
    </section>
  )
}

export default One
