import React from "react"
import CallOut from "../../components/CallOut"
import chart from "../../img/chart.jpg"
import * as styles from "./styles.module.css"

const Two = () => {
  return (
    <section className={styles.two}>
      <div className={styles.img}>
        <img src={chart} alt="Stock Chart" />
      </div>

      <p className={styles.hdg}>
        Do you know what you are actually buying in the ETF? If something went
        wrong are you actually getting the underlying stock gains or just the
        fees and losses?
      </p>

      <h2>Reduce Single Stock Risk & ETF</h2>

      <ul>
        <li>
          Many investors may choose an ETF to invest in and may not know how
          many fees and what stocks are currently being held. ETFs are also
          vulnerable to market manipulation via short shells and algorithmic buy
          sells orders in <strong>High Frequency Trading</strong>.
        </li>

        <li>
          Our model provides you with the tools to create your own personal
          non-exchange traded fund. Real ownership, real dividends, and less
          fees.
        </li>
      </ul>

      <p>
        The ETF Stock Report will provide outperformance of the underlying stock
        index. It is essential/critical to monitor the portfolio as new stocks
        come into the model and old stocks exit the model.
      </p>

      <p>
        <strong>You as an investor will be owning the outright stocks.</strong>{" "}
        With all the chaos in the news and the market, it is it critical that
        you be discerning in your investments.
      </p>

      <CallOut />
    </section>
  )
}
export default Two
